import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Card,
  Container,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Button,
  Typography,
  InputAdornment,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { addAdminUser, addAppUser, addLeveledSubDistributor } from "src/api/megs-api-api";
import { FormProvider, RHFAutocomplete, RHFDatePicker, RHFTextField } from "src/components/hook-form";
import { useSnackbar } from "src/components/snackbar";
import * as Yup from "yup";
import { PATH_DASHBOARD } from "src/routes/paths";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import { useSettingsContext } from "src/components/settings";
import Iconify from "src/components/iconify";
import { isAfter, isBefore, isSameDay } from "date-fns";
import { format } from "date-fns";
import { createPromoRule, getPromoRuleData } from "src/api/spar-api";
import LoadingScreen from "src/components/loading-screen";

const ruleTextRegex = /^[A-Za-z0-9- ]+$/;

const ManageRule = () => {
  const { themeStretch } = useSettingsContext();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { id: promoRuleId } = useParams();
  const location = useLocation();

  const pageModeType = location.pathname.includes("view");

  const [loading, setLoading] = useState(false);
  const [ruleType, setRuleType] = useState("Individual");

  const defaultProductList = [];

  const promoValidationSchema = Yup.object({
    ...(ruleType == "Basket Total" && {
      rule_name: Yup.string()
        .required("Please Enter Rule Name")
        .test("rule_name", "Rule Name is not valid", (value) => ruleTextRegex.test(value)),
      rule_description: Yup.string()
        .optional()
        .test("rule_description", "Rule Description is not valid", (value) => value == "" || ruleTextRegex.test(value)),
      rewards: Yup.string()
        .required("Please Enter Rewards Value")
        .test("rewards", "Reward Value is not valid", (value) => typeof value == "string" && value >= 0),
      basket_total: Yup.string()
        .required("Please Enter Basket  Total")
        .test("basket_total", "Basket Total is not valid", (value) => typeof value == "string" && value >= 0),
      rule_start_date: Yup.date().required("Start date is required"),
      rule_end_date: Yup.date().required("End date is required").min(Yup.ref("rule_start_date"), "End date cannot be earlier than start date"),
    }),
    ...(ruleType != "Basket Total" && {
      rule_name: Yup.string()
        .required("Please Enter Rule Name")
        .test("rule_name", "Rule Name is not valid", (value) => ruleTextRegex.test(value)),
      rule_description: Yup.string()
        .optional()
        .test("rule_description", "Rule Description is not valid", (value) => value == "" || ruleTextRegex.test(value)),
      rewards: Yup.string()
        .required("Please Enter Rewards Value")
        .test("rewards", "Reward Value is not valid", (value) => typeof value == "string" && value >= 0),
      maximum_quantiy: Yup.string()
        .required("Please Enter Maximum Quantity")
        .test("maximum_quantiy", "Maximum Quantity is not valid", (value) => typeof value == "string" && value >= 0),
      rule_start_date: Yup.date().required("Start date is required"),
      rule_end_date: Yup.date().required("End date is required").min(Yup.ref("rule_start_date"), "End date cannot be earlier than start date"),
      productList: Yup.array().of(
        Yup.object().shape({
          barcode_number_value: Yup.number().required("Barcode Number Value Required"),
          minimum_quantiy_value: Yup.number().required("Minimum Quantiy Value Required"),
        })
      ),
    }),
  });

  const [productList, setProductList] = useState(defaultProductList);

  const [promoRuleData, setPromoRuleData] = useState();
  const [promoRuleSchema, setPromoRuleSchema] = useState(promoValidationSchema);

  const ruleTypeValues = ["Individual", "Bundle", "Basket Total"];

  const defaultValues = {
    productList: defaultProductList,
  };

  const methods = useForm({
    mode: "onTouched",
    resolver: yupResolver(promoRuleSchema),
    defaultValues,
    shouldFocusError: true,
    disabled: true,
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
    clearErrors,
    setError,
    reset,
  } = methods;

  const values = watch();

  const onProcessSubmit = async () => {
    let newRuleRequestPayload = {};
    if (ruleType === "Basket Total") {
      newRuleRequestPayload = {
        ruleType: "basket_total",
        title: values.rule_name,
        description: values.rule_description,
        basketTotal: Number(values.basket_total),
        promoData: Number(values.rewards),
        promoStartDate: format(values.rule_start_date, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        promoEndDate: format(values.rule_end_date, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      };
    } else if (ruleType === "Individual") {
      newRuleRequestPayload = {
        ruleType: "individual",
        title: values.rule_name,
        description: values.rule_description,
        promoData: Number(values.rewards),
        gtinBulk: [
          {
            gtin: Number(values.barcode_number_0),
            minQty: Number(values.minimum_quantiy_0),
            maxQty: Number(values.maximum_quantiy),
          },
        ],
        promoStartDate: format(values.rule_start_date, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        promoEndDate: format(values.rule_end_date, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      };
    } else {
      const totalNumberOfBarcodes = Object.keys(values).filter((value) => value.includes("barcode_number_")).length;

      const gtinBulkData = Array.from({ length: totalNumberOfBarcodes }, (_, i) => {
        const barcode = values[`barcode_number_${i}`];
        if (barcode && barcode !== "") {
          // Check if barcode is not an empty string
          return {
            gtin: barcode,
            minQty: Number(values[`minimum_quantiy_${i}`]),
            maxQty: Number(values.maximum_quantiy),
          };
        }
        return null; // Return null if barcode is empty, to exclude it
      }).filter(Boolean);

      newRuleRequestPayload = {
        ruleType: "combo",
        title: values.rule_name,
        description: values.rule_description,
        promoData: values.rewards,
        gtinBulk: gtinBulkData,
        promoStartDate: format(values.rule_start_date, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        promoEndDate: format(values.rule_end_date, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
      };
    }
    try {
      // const { statusCode, message } = await createPromoRule(newRuleRequestPayload);
      // if (statusCode == 200) {
      //   enqueueSnackbar(message, {
      //     variant: "success",
      //     anchorOrigin: { vertical: "top", horizontal: "center" },
      //   });
      //   reset();
      // } else if (statusCode == 409) {
      //   enqueueSnackbar(message, {
      //     variant: "error",
      //     anchorOrigin: { vertical: "top", horizontal: "center" },
      //   });
      // }
    } catch (error) {
      console.error(error);
    }
  };

  const getPromoDataRuleType = (ruleType) => {
    if (ruleType == "individual") {
      return "Individual";
    } else if (ruleType == "combo") {
      return "Bundle";
    } else {
      return "Basket Total";
    }
  };

  const fetchRuleData = async () => {
    setLoading(true);
    try {
      const { statusCode, message, data } = await getPromoRuleData(promoRuleId);
      if (statusCode == 200) {
        const promoDataRuleType = getPromoDataRuleType(data.ruleType);
        setRuleType(promoDataRuleType);
        setPromoRuleData(data);
      } else if (statusCode == 409) {
        enqueueSnackbar(message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  //* Use Effects
  useEffect(() => {
    fetchRuleData();
  }, []);

  useEffect(() => {
    console.log("==============================================================");
    console.log(" promoRuleData================> ", promoRuleData);
    console.log("==============================================================");
    if (ruleType !== "Basket Total") {
      const promoProductList = [];
      const promoRuleGtinList = promoRuleData?.promoRuleGtin || [];

      promoRuleGtinList.forEach((product) => {
        promoProductList.push({
          barcode_number_value: product.gtin,
          minimum_quantiy_value: product.minQty,
        });
      });
      setValue("rule_name", promoRuleData?.title);
      setValue("rule_description", promoRuleData?.description);
      setValue("maximum_quantiy", promoRuleGtinList[0]?.maxQty);
      setValue("rewards", promoRuleData?.promoData);
      setValue("rule_start_date", promoRuleData?.promoStartDate);
      setValue("rule_end_date", promoRuleData?.promoEndDate);
      setValue("productList", promoProductList);
    } else {
      setValue("rule_name", promoRuleData?.title);
      setValue("rule_description", promoRuleData?.description);
      setValue("basket_total", promoRuleData?.basketTotal);
      setValue("rewards", promoRuleData?.promoData);
      setValue("rule_start_date", promoRuleData?.promoStartDate);
      setValue("rule_end_date", promoRuleData?.promoEndDate);
    }
  }, [promoRuleData]);
  return loading ? (
    <>
      <LoadingScreen />
    </>
  ) : (
    <>
      <Helmet>
        <title> Rule Management | megsApp </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "xl"}>
        <CustomBreadcrumbs
          heading="Rule Management"
          links={[
            {
              name: "Dashboard",
              href: PATH_DASHBOARD.root,
            },
            {
              name: "Rule Management",
              href: PATH_DASHBOARD.ruleManagement.root,
            },
            {
              name: "Manage Rule",
            },
          ]}
        />
        <FormProvider methods={methods} onSubmit={handleSubmit(onProcessSubmit)}>
          <Card sx={{ p: 3, mb: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Typography paragraph variant="overline" sx={{ color: "text.disabled" }}>
                  Rule Type
                </Typography>
                <Select
                  id="role-type-id"
                  value={ruleType}
                  placeholder="Please enter a value"
                  sx={{
                    width: 250,
                    mt: 1,
                  }}
                  disabled
                >
                  {ruleTypeValues.map((rule) => (
                    <MenuItem key={rule} value={rule}>
                      {rule}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            {ruleType && (
              <Grid container>
                {ruleType === "Basket Total" ? (
                  <>
                    <Grid container spacing={3} sx={{ mb: 2, mt: 1 }}>
                      <Grid item xs={12} sm={6} md={3}>
                        <RHFTextField type="text" name="rule_name" label="Rule Name*" />
                      </Grid>
                      <Grid item xs={12} sm={6} md={9}>
                        <RHFTextField type="text" multiline={true} name="rule_description" label="Rule Description" />
                      </Grid>
                      <Grid item xs={6} sm={6} md={3}>
                        <RHFTextField type="text" name="basket_total" label="Basket Total*" />
                      </Grid>
                      <Grid item xs={6} sm={6} md={3}>
                        <RHFTextField
                          type="text"
                          name="rewards"
                          label="Reward*"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">MB</InputAdornment>,
                          }}
                        />
                      </Grid>
                      <Grid container item columnSpacing={5} xs={12}>
                        <Grid item xs={3}>
                          <RHFDatePicker name="rule_start_date" label="Start On*" defaultValue={"today"} maxDate={"yesterday"} disablePast disabled={true} />
                        </Grid>
                        <Grid item xs={3}>
                          <RHFDatePicker name="rule_end_date" label="End On" disablePast disabled={true} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Grid container spacing={3} sx={{ mb: 2, mt: 1 }}>
                    <Grid item xs={12} sm={6} md={3}>
                      <RHFTextField name="rule_name" label="Rule Name*" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={9}>
                      <RHFTextField type="text" multiline={true} name="rule_description" label="Rule Description" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                      <RHFTextField type="text" name="maximum_quantiy" label="Maximum Quantity*" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                      <RHFTextField
                        type="text"
                        name="rewards"
                        label="Reward*"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">MB</InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid container item columnSpacing={5} xs={12}>
                      <Grid item xs={3}>
                        <RHFDatePicker name="rule_start_date" label="Start On*" defaultValue={"today"} maxDate={"yesterday"} disablePast disabled={true} />
                      </Grid>
                      <Grid item xs={3}>
                        <RHFDatePicker name="rule_end_date" label="End On*" disablePast disabled={true} />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Card>

          {ruleType !== "Basket Total" && (
            <>
              <Card sx={{ p: 3, mb: 3 }}>
                <Typography paragraph variant="overline" sx={{ color: "text.disabled" }}>
                  Item Data
                </Typography>
                {values.productList.map((value, index) => (
                  <Grid
                    item
                    container
                    spacing={4}
                    xs={12}
                    sx={{
                      justifyContent: "flex-start",
                      alignItems: "center",
                      mt: 1,
                    }}
                    key={index}
                  >
                    <Grid item xs={ruleType === "Bundle" ? 4 : 6} md={4}>
                      <RHFTextField type="text" name={`productList[${index}].barcode_number_value`} label="Barcode Number*" />
                    </Grid>
                    <Grid item xs={ruleType === "Bundle" ? 4 : 6} md={4}>
                      <RHFTextField type="text" name={`productList[${index}].minimum_quantiy_value`} label="Minimum Quantity*" />
                    </Grid>
                    {ruleType === "Bundle" && values.productList.length > 1 && !pageModeType && (
                      <Grid item xs={4} sx={{ alignItems: "center" }}>
                        <Button onClick={(e) => {}} variant="soft" color="error" startIcon={<Iconify icon="eva:trash-2-outline" />}>
                          Remove
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                ))}
                {ruleType === "Bundle" && !pageModeType && (
                  <Button size="small" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => {}} sx={{ flexShrink: 0, mt: 1 }}>
                    Add Item
                  </Button>
                )}
              </Card>
            </>
          )}

          <Grid item xs={12} sm={12}>
            <Stack spacing={3} direction={"row"} alignItems="flex-end" justifyContent="space-between" sx={{ mt: 2 , p : 2 }}>
              <FormControlLabel
                control={<Switch />}
                label={
                  <Typography variant="subtitle1" noWrap>
                    Enable Rule
                  </Typography>
                }
                labelPlacement="start"
              />
              {!pageModeType && (
                <LoadingButton variant="contained" loading={isSubmitting} onClick={handleSubmit(onProcessSubmit)} disabled={errors.length > 0}>
                  Submit
                </LoadingButton>
              )}
            </Stack>
          </Grid>
        </FormProvider>
      </Container>
    </>
  );
};

export default ManageRule;
