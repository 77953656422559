import { DateRangePicker, SingleInputDateRangeField } from "@mui/x-date-pickers-pro";
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek, subDays, subMonths } from "date-fns";

export default function CustomDateRangePicker({ filterStartDate, filterEndDate, onFilterStartDate, onFilterEndDate }) {
  const shortcutsItems = [
    {
      label: "This Week",
      getValue: () => {
        const today = new Date();
        return [startOfWeek(today), endOfWeek(today)];
      },
    },
    {
      label: "Last Week",
      getValue: () => {
        const today = new Date();
        const startOfLastWeek = startOfWeek(subDays(today, 7));
        const endOfLastWeek = endOfWeek(subDays(today, 7));
        return [startOfLastWeek, endOfLastWeek];
      },
    },
    {
      label: "Last 7 Days",
      getValue: () => {
        const today = new Date();
        return [subDays(today, 7), today];
      },
    },
    {
      label: "Current Month",
      getValue: () => {
        const today = new Date();
        return [startOfMonth(today), endOfMonth(today)];
      },
    },
    {
      label: "Last Month",
      getValue: () => {
        const today = new Date();
        const startOfLastMonth = startOfMonth(subMonths(today, 1));
        const endOfLastMonth = endOfMonth(subMonths(today, 1));
        return [startOfLastMonth, endOfLastMonth];
      },
    },
    { label: "Reset", getValue: () => [null, null] },
  ];

  return (
    <DateRangePicker
      label="Date Range"
      sx={{
        // width: "60%",
        "& .MuiInputBase-input": {
          fontSize: "0.75rem",
          padding: "19.5px 14px",
        },
      }}
      startText="Start date"
      endText="End date"
      value={[filterStartDate, filterEndDate]}
      onChange={(newValue) => {
        onFilterStartDate(newValue[0]);
        onFilterEndDate(newValue[1]);
      }}
      slotProps={{
        shortcuts: {
          items: shortcutsItems,
        },
        actionBar: { actions: [] },
      }}
      slots={{
        field: SingleInputDateRangeField,
      }}
      calendars={2}
      toolbarFormat="dd MM yyyy"
      format="dd/MM/yy"
      formatDensity="dense"
    />
  );
}
