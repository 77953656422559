import * as Sentry from "@sentry/react";
import moment from "moment";
import axiosInstance from "src/utils/axios";
import { axiosApiInstance } from "./axios";
import { axiosAuthInstance, axiosAuthInstanceForFormData } from "./axiosAuth";

const base_url = process.env.REACT_APP_API_ENDPOINT;

export const getCustomerByMsisdn = async (iccid) => {
  // console.log("getCustomerByMsisdn");
  try {
    const url = `${base_url}/app/customer/iccid`;
    const { data } = await axiosAuthInstance.post(url, { iccid });
    // console.log("getCustomerByMsisdn", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getCustomerByIccid = async (iccid) => {
  // console.log("getCustomerByIccid");
  try {
    const url = `${base_url}/app/customer/iccid`;
    const { data } = await axiosAuthInstance.post(url, { iccid });
    // console.log("getCustomerByIccid", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const formatMsisdn = async (msisdn) => {
  try {
    const url = `${base_url}/app/formatmsisdn`;
    const { data } = await axiosApiInstance.post(url, { msisdn });
    // console.log("formatMsisdn", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const formatWhatsappMsisdn = async (msisdn) => {
  // console.log("formatMsisdn");
  try {
    const url = `${base_url}/app/format-whatsapp-msisdn`;
    const { data } = await axiosApiInstance.post(url, { msisdn });
    // console.log("formatMsisdn", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};
export const sendOtp = async (username) => {
  // console.log("sendOtp");
  try {
    const url = `${base_url}/app/password/otp`;
    const { data } = await axiosApiInstance.post(url, { email_address: username });
    // console.log("sendOtp: ", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const sendOtpWithMsisdn = async (msisdn) => {
  // console.log("sendOtp");
  try {
    const url = `${base_url}/app/password/msisdn/otp`;
    const { data } = await axiosInstance.post(url, { msisdn: msisdn });
    // console.log("sendOtp: ", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const resetPassword = async (data) => {
  // console.log("resetPassword: ", data);
  try {
    const url = `${base_url}/app/password/reset`;
    const { data: response } = await axiosApiInstance.post(url, data);

    // console.log("resetPassword response: ", response);
    if (response.status_code !== 1001) {
      // console.log("resetPassword: Error", response.data);
    }
    return response;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
    return ex;
  }
};

export const addDistributorLead = async (data) => {
  if (data.school_registration_type === "Public School") {
    data.company_name = data.school_name;
    data.company_registration_number = data.school_emis;
    data.company_registered_name = data.school_name;
    data.company_address_unit_block = data.school_address_unit_block;
    data.company_address_street_name_number = data.school_address_street_name_number;
    data.company_suburb = data.school_suburb;
    data.company_city = data.school_city;
    data.company_province = data.school_province;
    data.company_postal_code = data.school_postal_code;
  }
  // console.log("addDistributorLead: ", data);

  // format all the phone numbers
  let school_principal_phone_number = await formatMsisdn(data.school_principal_phone_number);
  if (school_principal_phone_number.data === null) {
    return { status_code: 9000, data: "Invalid school principal phone number" };
  }
  data.school_principal_phone_number = school_principal_phone_number.data;

  let school_contact_phone_number = await formatMsisdn(data.school_contact_phone_number);
  if (school_contact_phone_number.data === null) {
    return { status_code: 9000, data: "Invalid school contact phone number" };
  }

  data.school_contact_phone_number = school_contact_phone_number.data;
  let school_finance_person_phone_number = await formatMsisdn(data.school_finance_person_phone_number);
  if (school_finance_person_phone_number.data === null) {
    return { status_code: 9000, data: "Invalid school finance person phone number" };
  }
  data.school_finance_person_phone_number = school_finance_person_phone_number.data;

  try {
    const url = `${base_url}/app/distributor/leads/add`;
    // const url = `${base_url}/app/distributor/schools/add-lead`;
    const { data: response } = await axiosApiInstance.post(url, data);

    // console.log("addDistributorLead response: ", response);
    if (response.status_code !== 1001) {
      // console.log("addDistributorLead: Error", response.data);
    }
    return response;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
    return ex;
  }
};

export const addDistributorSimOrder = async (data, distributorDetails) => {
  // console.log("addDistributorSimOrder: ", data);
  try {
    let post_data = {
      distributor_name: distributorDetails.company_name,
      order_status: "Account Manager",
      order_client_id: distributorDetails.monday_id,
      order_person: "45002791",
      order_description: data.item.description,
      order_quantity: data.item.quantity,
      order_price_ex_vat: data.item.price,
      order_value: data.item.total,
    };

    // console.log("addDistributorSimOrder: ", post_data);
    const url = `${base_url}/app/monday/distribution/sim/order`;
    const { data: response } = await axiosAuthInstance.post(url, post_data);

    // console.log("addDistributorSimOrder response: ", response);

    return response;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
    return ex;
  }
};

export const addDistributorStore = async (formData) => {
  // console.log("addDistributorStore: ", formData);

  // Transform the form data to match the API's expected format
  const transformedData = {
    i_customer: formData.i_customer,
    name: formData.distributor_name,
    external_id: formData.distributor_external_id,
    phone_number: formData.distributor_phone,
    email_address: formData.distributor_email,
  };

  try {
    const url = `${base_url}/app/distributor/store/add`;
    const { data: response } = await axiosAuthInstance.post(url, transformedData);

    // console.log("addDistributorStore response: ", response);
    if (response.status_code !== 1001) {
      // Handle error
    }
    return response;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
    return ex;
  }
};

export const verifyEmailAddress = async (email_address) => {
  // console.log("verifyEmailAddress");
  try {
    const url = `${base_url}/app/email/verify`;
    const { data } = await axiosInstance.post(url, { email_address });
    // console.log("verifyEmailAddress", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getDistributorTransactionHistory = async (i_customer, start_date, end_date) => {
  // console.log("getDistributorTransactionHistory");
  /*
  {
    "i_customer": 1574,
    "start_date": "2023-08-01 00:00:00",
    "end_date": "2023-09-01 00:00:00"
  }

  const date = new Date('2022-08-31T22:00:00.000Z');
const dateString = date.toISOString().replace('T', ' ').replace(/\.\d{3}Z/, '');
console.log(dateString); // Output: 2022-08-31 22:00:00
  */

  // console.log("getDistributorTransactionHistory", start_date, end_date);
  const post_data = {
    i_customer: i_customer,
    start_date: start_date
      .toISOString()
      .replace("T", " ")
      .replace(/\.\d{3}Z/, ""),
    end_date: end_date
      .toISOString()
      .replace("T", " ")
      .replace(/\.\d{3}Z/, ""),
  };

  try {
    const url = `${base_url}/app/distributor/transactions`;
    const { data } = await axiosAuthInstance.post(url, post_data);
    // console.log("getDistributorTransactionHistory", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getResellerTransactionHistory = async (i_customer, start_date, end_date) => {
  // console.log("getDistributorTransactionHistory");
  const post_data = {
    i_customer: i_customer,
    start_date: start_date
      .toISOString()
      .replace("T", " ")
      .replace(/\.\d{3}Z/, ""),
    end_date: end_date
      .toISOString()
      .replace("T", " ")
      .replace(/\.\d{3}Z/, ""),
  };

  try {
    const url = `${base_url}/app/reseller/transactions`;
    const { data } = await axiosAuthInstance.post(url, post_data);
    // console.log("getDistributorTransactionHistory", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getResellerProducts = async (network, category) => {
  try {
    // console.log("getResellerProducts", network, category);
    const url = `${base_url}/app/var/pinless/bundle/?network=${network}&category=${category}`;
    const { data } = await axiosAuthInstance.get(url);
    // console.log("getResellerProducts", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getDistributorProducts = async (distributor_i_customer) => {
  try {
    const url = `${base_url}/app/distributor/products?distributor_i_customer=${distributor_i_customer}`;
    const { data } = await axiosAuthInstance.get(url);
    // console.log("getDistributorProducts", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getResellerCategories = async (network) => {
  try {
    const url = `${base_url}/app/var/pinless/categories/?network=${network}`;
    const { data } = await axiosAuthInstance.get(url);
    // console.log("getResellerCategories", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const processResellerSale = async (payload) => {
  try {
    const url = `${base_url}/app/var/pinless/airtime/sale`;
    const { data } = await axiosAuthInstance.post(url, payload);

    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
    return {
      status_code: 9000,
      data: "Error processing sale",
    };
  }
};

export const processResellerDataSale = async (payload) => {
  try {
    const url = `${base_url}/app/var/pinless/data/sale`;
    const { data } = await axiosAuthInstance.post(url, payload);

    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
    return {
      status_code: 9000,
      data: "Error processing sale",
    };
  }
};

export const addCustomerLead = async (payload) => {
  // console.log("addLead");
  try {
    const url = `${base_url}/app/customer/leads`;
    const { data } = await axiosAuthInstance.post(url, payload);
    // console.log("addLead", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const verifySimCard = async (iccid) => {
  // console.log("verifySimCard");
  try {
    const url = `${base_url}/app/sim_card/verify/sale`;
    const { data } = await axiosAuthInstance.post(url, { iccid });
    // console.log("verifySimCard", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const validateID = async (id_number) => {
  // console.log("verifySimCard");
  try {
    const url = `${base_url}/app/customer/rica/id`;
    const { data } = await axiosAuthInstance.post(url, { id_number });
    // console.log("verifySimCard", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getIdentity = async (id_number) => {
  // console.log("verifySimCard");
  try {
    const url = `${base_url}/app/customer/rica/identity`;
    const { data } = await axiosAuthInstance.post(url, { id_number });
    // console.log("verifySimCard", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const validatePostalCode = async (postal_code) => {
  // console.log("verifySimCard");
  try {
    const url = `${base_url}/app/customer/verify/postalcode`;
    const { data } = await axiosAuthInstance.post(url, { postal_code });
    // console.log("verifySimCard", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const addCustomerDetails = async (values) => {
  // console.log('addRicaUser')
  try {
    let { id_number } = values;
    if (values.id_type === "2" || values.id_type === "3") {
      id_number = values.passport_number;
    }

    values.msisdn = await formatMsisdn(values.msisdn);

    // if alt_phone_number is an object and the keys are countryCode, areaCode, phoneNumber
    if (values?.alt_phone_number && typeof values?.alt_phone_number === "object") {
      // check that the keys are countryCode, areaCode, phoneNumber
      if (values?.alt_phone_number?.countryCode && values?.alt_phone_number?.areaCode && values?.alt_phone_number?.phoneNumber) {
        values.alt_phone_number = `${values?.alt_phone_number?.countryCode}${values?.alt_phone_number?.areaCode}${values?.alt_phone_number?.phoneNumber}`;
      }
    }

    if (values?.whatsapp_msisdn && typeof values?.whatsapp_msisdn === "object") {
      values.whatsapp_msisdn = `${values?.whatsapp_msisdn?.countryCode}${values?.whatsapp_msisdn?.areaCode}${values?.whatsapp_msisdn?.phoneNumber}`;
    }

    // console.log(values.alt_phone_number, values.whatsapp_msisdn);

    let post_data = {
      distributor_i_customer: values.distributor_i_customer,
      id_number: id_number,
      id_url: "",
      id_type: values.id_type,
      nationality: values.citizenship,
      first_name: values.first_name,
      last_name: values.last_name,
      phone_number: values.msisdn.data,
      whatsapp_msisdn: values.whatsapp_msisdn,
      alt_phone_number: values.alt_phone_number,
      email: values.email,
      gender: values.gender,
      language: values.language,
      date_of_birth: moment(values.date_of_birth).format("YYYY-MM-DD"),
      address_1: values.address_1 ?? "22 Karee Street",
      address_2: values.address_2 ?? "Centurion",
      city: values.city ?? "Pretoria",
      province: values.province ?? 3,
      zip: values.postal_code ?? "0062",
      sim_serial_number: values.iccid,
      payment_type: "cash",
      port: false,
      self_rica: false,
      self_rica_channel: values.self_rica_channel,
      rica_agent: values.rica_agent,
      port_network: "",
      external_id: values.external_id,
      address_valid: true,
      photo_valid: values.photo_valid,
    };

    // console.log('post_data', post_data)
    // return {status_code: 1001, data: 'Success!'}
    // return {status_code: 9001, data: 'Please provide a MSISDN and network for porting'}
    // return {status_code: 9002, data: 'You do not have sufficient funds to process the transaction, please top up your trader wallet'}
    // return {status_code: 9003, data: 'Error connecting to RICA, please submit again'}
    // return {status_code: 9004, data: 'SIM Card has already been RICAd, please use another SIM card'}
    // return {status_code: 9005, data: 'Error with customer data: Error. Please update the customer fields and submit again'}
    // return {status_code: 9006, data: 'Error connecting to RICA, please submit again. If the issue persists, please contact support'}
    // return {status_code: 9007, data: 'Error creating the Customer in PortaOne, please submit again. If the issue persists, please contact support'}

    // console.log("Calling API with: ", { post_data });
    const url = `${base_url}/app/customer/add/details`;
    let { data } = await axiosAuthInstance.post(url, post_data, { timeout: 120000 });

    return data;
    // return { status_code: 1001, data: "Success!" };
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
    return { status_code: 9000, data: ex.message };
  }
};

export const formatICCID = async (iccid) => {
  // console.log("formatICCID");
  try {
    const url = `${base_url}/app/sim_card/iccid/format`;
    const { data } = await axiosAuthInstance.post(url, { iccid });
    // console.log("formatICCID", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getDistributorSimCards = async (distributor_i_customer, page, rowsPerPage, search, distributor_sub_distributor_id) => {
  try {
    const url = `${base_url}/app/sim_card/list-sims`;
    const { data } = await axiosAuthInstance.post(
      url,
      distributor_i_customer === "All"
        ? { limit: rowsPerPage, page: page + 1, search }
        : { distributor_i_customer, limit: rowsPerPage, page: page + 1, search, distributor_sub_distributor_id }
    );
    // console.log("getDistributorSimCards", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getSimCardProducts = async () =>{
  try{
    const url = `${base_url}/app/distributor/sim_card_order/products`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  }catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
}

export const orderSimCardsInvoice = async (simCardData) =>{
  try{
    const url = `${base_url}/app/distributor/sim_card_order`;
    const { data } = await axiosAuthInstance.post(url,simCardData);
    return data;
  }catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
}


export const suspendCustomer = async (i_customer) => {
  // console.log(suspendCustomer);
  try {
    const url = `${base_url}/app/customer/suspend`;
    const { data } = await axiosAuthInstance.post(url, { i_customer });
    // console.log("suspendCustomer", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const unsuspendCustomer = async (i_customer) => {
  // console.log(unsuspendCustomer);
  try {
    const url = `${base_url}/app/customer/unsuspend`;
    const { data } = await axiosAuthInstance.post(url, { i_customer });
    // console.log("unsuspendCustomer", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const resendOTP = async (msisdn) => {
  // console.log(resendOTP);
  try {
    const url = `${base_url}/customer/password/otp`;
    const { data } = await axiosAuthInstance.post(url, { msisdn });
    // console.log("resendOTP", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const resetAppPassword = async (i_customer) => {
  // console.log(resetAppPassword);
  try {
    const url = `${base_url}/app/password/reset-customer-password`;
    const { data } = await axiosAuthInstance.post(url, { i_customer });
    // console.log("resetAppPassword", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const updateCustomerDisplayName = async (i_customer, display_name) => {
  // console.log(updateCustomerAppDisplayName);
  try {
    const url = `${base_url}/app/customer/update-display-name`;
    const { data } = await axiosAuthInstance.post(url, { i_customer, display_name });
    // console.log("updateCustomerAppDisplayName", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const topUpCustomer = async (msisdn, data_value, airtime_value, wallet_value, reason, portal_user, type) => {
  // console.log("topUpCustomer", msisdn, data_value, airtime_value, reason, portal_user);
  try {
    const url = `${base_url}/app/cc/manual_top_up`;
    const { data } = await axiosAuthInstance.post(url, { msisdn, data_value, airtime_value, wallet_value, reason, portal_user, type });
    // console.log("topUpCustomer", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getVoucherDetails = async (code) => {
  // console.log(getVoucherDetails);
  try {
    const url = `${base_url}/app/voucher/info`;
    const { data } = await axiosAuthInstance.post(url, { code });
    // console.log("getVoucherDetails", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getVoucherList = async (page = 1, limit = 25, search, filterStatus, filterService, filterStartDate, filterEndDate, distributor_i_customer) => {
  // console.log("getVoucherList");
  // console.log("filterStartDate", filterStartDate);
  // console.log("filterEndDate", filterEndDate);
  // console.log("filterStatus", filterStatus);
  // console.log("filterService", filterService);
  // console.log("search", search);

  try {
    const url = `${base_url}/app/voucher/list`;
    // distributor_i_customer === "All" ? { limit: rowsPerPage, page: page + 1 } : { distributor_i_customer, limit: rowsPerPage, page: page + 1 }
    const { data } = await axiosAuthInstance.post(
      url,
      distributor_i_customer === "All"
        ? {
            page,
            limit,
            search,
            filterStatus,
            filterService,
            filterStartDate,
            filterEndDate,
          }
        : {
            page,
            limit,
            search,
            filterStatus,
            filterService,
            filterStartDate,
            filterEndDate,
            distributor_i_customer,
          }
    );
    // console.log("getVoucherList", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getBrands = async () => {
  // console.log(getBrands);
  try {
    const url = `${base_url}/app/distributor/brands`;
    const { data } = await axiosAuthInstance.get(url);
    // console.log("getBrands", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getBrandByDomain = async (domain) => {
  // console.log(getBrandByDomain);
  try {
    const url = `${base_url}/app/distributor/brand-info/?domain=${domain}`;
    const { data } = await axiosAuthInstance.get(url, { domain });
    // console.log("getBrandByDomain", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getSIMCardDetails = async (iccid) => {
  // console.log(getSIMCardDetails);
  try {
    const url = `${base_url}/app/sim_card/details`;
    const { data } = await axiosAuthInstance.post(url, { iccid });
    // console.log("getSIMCardDetails", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const addAppUser = async (data) => {
  // console.log(addAppUser);
  const post_data = {
    list: [data],
  };
  try {
    // console.log("addAppUser post_data: ", post_data);
    const url = `${base_url}/app/user/add`;
    const { data: response } = await axiosAuthInstance.post(url, post_data);
    // console.log("addAppUser", response);
    return response;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

// export const listAppUsers = async (distributor_i_customer) => {
//   console.log("listAppUsers: ", distributor_i_customer);
//   try {
//     const url = `${base_url}/app/user/list`;
//     const { data } = await axiosAuthInstance.post(url, { distributor_i_customer });
//     console.log("listAppUsers", data);
//     return data;
//   } catch (ex) {
//     console.error(ex);
// //   }
// };

export const getESim = async (distributor_i_customer, activation_id) => {
  // console.log("getESim: ", distributor_i_customer);
  try {
    const url = `${base_url}/app/eSIM/getSimCard?distributor_i_customer=${distributor_i_customer}&activation_id=${activation_id}`;
    // console.log("getESim url: ", url);
    const { data } = await axiosAuthInstance.get(url);

    // console.log("getESim", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getESimCount = async (distributor_i_customer) => {
  // console.log("getESimCount: ", distributor_i_customer);
  try {
    const url = `${base_url}/app/eSIM/get-esims-count?distributor_i_customer=${distributor_i_customer}`;

    const { data } = await axiosAuthInstance.get(url);

    // console.log("getESimCount", data);
    return data;
    // return {
    //   "status_code": 1001,
    //   "data": [
    //     {
    //       "activation_id": 66,
    //       "description": "eSIM R9 for 3GB - 7 Day",
    //       "count": 750
    //     },
    //     {
    //       "activation_id": 67,
    //       "description": "eSIM R99 for 10GB - 7 Day",
    //       "count": 375
    //     },
    //     {
    //       "activation_id": 68,
    //       "description": "eSIM R199 for 25GB - 30 Day",
    //       "count": 125
    //     }
    //   ]
    // }
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getAppUserDetails = async (id) => {
  // console.log("App User Id", id);
  try {
    const url = `${base_url}/app/user?id=${id}`;
    const { data } = await axiosAuthInstance.get(url);
    const userRoles = data?.data?.roles?.map((role) => ({
      label: role.role === "distributor" ? "Distributor" : role.role === "sub-distributor" ? "Sub Distributor" : role.role === "admin" ? "Admin" : role.role,
      value: role.role,
    }));
    data.data.distributor_i_customer = data?.data?.roles?.[0]?.i_customer;
    data.data.roles = userRoles;
    // console.log("User Data", data);

    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getAppUserDistributors = async (id) => {
  // console.log("App User Id", id);
  try {
    const url = `${base_url}/app/user-roles?id=${id}`;
    const { data } = await axiosAuthInstance.get(url);
    // const userRoles = data?.data?.map((item) => {
    //   item?.roles?.map((role) => ({
    //     label: role === "distributor" ? "Distributor" : role === "sub-distributor" ? "Sub Distributor" : role === "admin" ? "Admin" : role,
    //     value: role,
    //   }));
    //   item.roles = userRoles;
    // });
    // console.log("Distributor data", data);

    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getAppUserDetailsForDistributor = async (id, i_customer) => {
  // console.log("App User Id", id);
  try {
    const url = `${base_url}/app/user-distributor?id=${id}&distributor_i_customer=${i_customer}`;
    const { data } = await axiosAuthInstance.get(url);
    const userRoles = data?.data?.roles?.map((role) => ({
      label: role.role === "distributor" ? "Distributor" : role.role === "sub-distributor" ? "Sub Distributor" : role.role,
      value: role.role,
    }));
    data.data.distributor_i_customer = data?.data?.roles?.[0]?.i_customer;
    data.data.roles = userRoles;
    // console.log("User Data", data);

    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const editAppUserData = async (userData) => {
  const roleValues = userData.role.map((role) => role.value);
  const updatedUserData = {
    ...userData,
    role: roleValues,
  };
  // console.log("editAppUser", updatedUserData);
  try {
    const url = `${base_url}/app/user/edit`;
    const { data } = await axiosAuthInstance.post(url, updatedUserData);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const listAdminAppUsers = async () => {
  try {
    const url = `${base_url}/app/admin`;
    const { data } = await axiosAuthInstance.get(url);
    // console.log("listAppUsers", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getAdminUserDetails = async (id) => {
  try {
    const url = `${base_url}/app/admin/${id}`;
    const { data } = await axiosAuthInstance.get(url);
    // console.log("listAppUsers", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const editAdminUserData = async (id, adminData) => {
  // console.log("editAppUser", adminData);
  try {
    const url = `${base_url}/app/admin/${id}`;
    const { data } = await axiosAuthInstance.put(url, adminData);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const addAdminUser = async (data) => {
  try {
    // console.log("addAppUser post_data: ", data);
    const url = `${base_url}/app/admin`;
    const { data: response } = await axiosAuthInstance.post(url, data);
    // console.log("addAppUser", response);
    return response;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const deleteAdminUser = async (id) => {
  try {
    const url = `${base_url}/app/admin/${id}`;
    const { data } = await axiosAuthInstance.delete(url);
    // console.log("listAppUsers", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const listAppUsers = async (distributor_i_customer, distributor_sub_distributor_id, role) => {
  try {
    let url = "";
    if (distributor_i_customer === "All" || distributor_i_customer === undefined) {
      url = `${base_url}/app/user`;
    } else {
      url = `${base_url}/app/user/?distributor_i_customer=${distributor_i_customer}&distributor_sub_distributor_id=${distributor_sub_distributor_id}&role=${role}`;
    }
    const { data } = await axiosAuthInstance.get(url);
    let filteredUsers = data.data.filter(
      (item) => item.role === "distributor" || item.role === "sub-distributor" || item.role === "support_agent" || item.role === "referrer" || item.role === "agent"
    );
    data.data = filteredUsers;
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const checkUserExists = async (email) => {
  try {
    const url = `${base_url}/app/user/${email}`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const addAppUserRoles = async (userData) => {
  try {
    const url = `${base_url}/app/user`;
    const { data } = await axiosAuthInstance.post(url, userData);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const deleteAppUserRole = async (id, i_customer, role) => {
  try {
    const url = `${base_url}/app/user/${id}?distributor_i_customer=${i_customer}&role=${role}`;
    const { data } = await axiosAuthInstance.delete(url);
    // console.log("listAppUsers", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const listActiveSubscriptions = async (distributor_i_customer) => {
  try {
    let url = "";
    if (distributor_i_customer === "All" || distributor_i_customer === undefined) {
      url = `${base_url}/app/subscription/active`;
    } else {
      url = `${base_url}/app/subscription/active/?distributor_i_customer=${distributor_i_customer}`;
    }
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getSubscriptionDetails = async (id) => {
  try {
    const url = `${base_url}/app/subscription/billing/${id}`;
    const { data } = await axiosAuthInstance.get(url);
    // console.log("listAppUsers", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getCustomerBalanceData = async (i_customer) => {
  try {
    const url = `${base_url}/app/customer/balance`;
    const { data } = await axiosAuthInstance.post(url, { i_customer: i_customer });
    // console.log("getCustomerBalanceData", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getCustomerBalanceDetails = async (i_customer) => {
  try {
    const url = `${base_url}/app/customer/balance/wallet`;
    const { data } = await axiosAuthInstance.post(url, { i_customer: i_customer });
    // console.log("getCustomerBalanceData", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getCustomerTransactionHistory = async (i_customer, start_date, end_date, limit, page) => {
  try {
    let url = `${base_url}/app/customer/transactions?i_customer=${i_customer}&from_date=${moment(start_date).format("YYYY-MM-DD")}&to_date=${moment(end_date).format("YYYY-MM-DD")}`;
    if (limit != null && page != null) {
      url = `${base_url}/app/customer/transactions?i_customer=${i_customer}&from_date=${moment(start_date).format("YYYY-MM-DD")}&to_date=${moment(end_date).format("YYYY-MM-DD")}&limit=${limit}&page=${page}`;
    }
    const { data } = await axiosAuthInstance.get(url);
    // console.log("getCustomerBalanceData", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getWalletTransactionHistory = async (i_customer, start_date, end_date, limit, page) => {
  try {
    let url = `${base_url}/app/wallet/transactions?i_customer=${i_customer}&from_date=${moment(start_date).format("YYYY-MM-DD")}&to_date=${moment(end_date).format("YYYY-MM-DD")}`;
    if (limit != null && page != null) {
      url = `${base_url}/app/wallet/transactions?i_customer=${i_customer}&from_date=${moment(start_date).format("YYYY-MM-DD")}&to_date=${moment(end_date).format("YYYY-MM-DD")}&limit=${limit}&page=${page}`;
    }
    const { data } = await axiosAuthInstance.get(url);
    // console.log("getWalletTransactionHistory", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getSmsTransactionHistory = async (msisdn, start_date, end_date, limit, page, messageFilterName) => {
  try {
    let url = `${base_url}/app/customer/transactions/sms?msisdn=${msisdn}&from_date=${moment(start_date).format("YYYY-MM-DD")}&to_date=${moment(end_date).format("YYYY-MM-DD")}&message_text=${messageFilterName}`;
    if (limit != null && page != null) {
      url = `${base_url}/app/customer/transactions/sms?msisdn=${msisdn}&from_date=${moment(start_date).format("YYYY-MM-DD")}&to_date=${moment(end_date).format("YYYY-MM-DD")}&message_text=${messageFilterName}&limit=${limit}&page=${page}`;
    }
    const { data } = await axiosAuthInstance.get(url);
    // console.log("getWalletTransactionHistory", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const listAllProducts = async () => {
  // console.log("Inside listAllProducts");
  try {
    const url = `${base_url}/products`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const listAllProductsByGroup = async () => {
  // console.log("Inside listAllProducts");
  try {
    const url = `${base_url}/product/expiryPeriod`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const listAllProductGroup = async () => {
  try {
    const url = `${base_url}/product/group`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getProductDetails = async (id) => {
  try {
    const url = `${base_url}/product/${id}`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const updateProductGroupMapping = async (mappingData) => {
  try {
    const url = `${base_url}/product/productGroupProduct/modiify`;
    const { data } = await axiosAuthInstance.put(url, mappingData);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getProductGroupDetails = async (id) => {
  try {
    const url = `${base_url}/product/group/details/${id}`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const listAllProductTypes = async () => {
  try {
    const url = `${base_url}/product/types`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const updateProductStatus = async (statusData) => {
  // console.log("updateProductStatus", statusData);
  try {
    const url = `${base_url}/product/updateStatus`;
    const { data } = await axiosAuthInstance.put(url, statusData);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const updateProductGroupStatus = async (statusData) => {
  // console.log("updateProductStatus", statusData);
  try {
    const url = `${base_url}/product/productGroup/updateStatus`;
    const { data } = await axiosAuthInstance.put(url, statusData);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const listAllEscalations = async (i_customer, status, start_date, end_date) => {
  // console.log("Inside listAllEscalations", { i_customer, status, start_date, end_date });
  try {
    const url = `${base_url}/escalation/get/distributor/${i_customer}`;
    const { data } = await axiosAuthInstance.post(url, status === "open" ? { status } : { status, start_date, end_date });
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getEscalationDetails = async (id) => {
  try {
    const url = `${base_url}/escalation/get/${id}`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const createEscalation = async (msisdn, distributor_i_customer, app_user, title, description, files, iccid) => {
  // console.log("Inside createEscalation", { msisdn, distributor_i_customer, app_user, title, description, iccid });
  const formDataPayload = new FormData();
  formDataPayload.append("msisdn", msisdn);
  formDataPayload.append("iccid", iccid);
  formDataPayload.append("distributor_i_customer", distributor_i_customer);
  formDataPayload.append("app_user", app_user);
  formDataPayload.append("title", title);
  formDataPayload.append("description", description);
  if (files.length) {
    files.map((file) => {
      formDataPayload.append("files", file);
    });
  }
  try {
    const url = `${base_url}/escalation/create`;
    const { data } = await axiosAuthInstanceForFormData.post(url, formDataPayload);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

// export const uploadAttachments = async (payload) => {
//   try {
//     const formDataPayload = new FormData();
//     formDataPayload.append("files", payload.file);
//     const { data } = await axiosAuthInstanceForFormData.post("/escalation/upload-attachments", formDataPayload);
//     return data;
//   } catch (ex) {
//     console.log(ex);
//     return {
//       status_code: 500,
//       message: ex.message,
//     };
//   }
// };

export const getDistributorBalances = async (i_customer, refresh) => {
  try {
    const url = `${base_url}/app/distributor/balance`;
    const { data } = await axiosAuthInstance.post(url, { i_customer, refresh });
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getRedirectSVIXURL = async (i_customer) => {
  try {
    const url = `${base_url}/app/svixportal/url?distributor_i_customer=${i_customer}`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getBatchRequests = async (i_customer) => {
  try {
    const url = `${base_url}/app/subscription/batchRequest?distributor_i_customer=${i_customer}`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getBatchDetails = async (batch_id) => {
  try {
    const url = `${base_url}/app/subscription/batchRequest/details?batch_request_id=${batch_id}`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const listAllTrainingVideoCategories = async (category_name) => {
  try {
    const url = `${base_url}/trainingVideos/categories?type=${category_name}`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const listAllTrainingVideos = async (category_type, distributor_i_customer, role) => {
  try {
    const url = `${base_url}/trainingVideos/videos?category_type=${category_type}&distributor_i_customer=${distributor_i_customer}&role=${role}`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const listAllVideos = async () => {
  try {
    const url = `${base_url}/trainingVideos/videos`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const uploadTrainingVideoDetails = async (name, description, link, categoryId, brand, files) => {
  const formDataPayload = new FormData();
  formDataPayload.append("name", name);
  formDataPayload.append("description", description);
  formDataPayload.append("link", link);
  formDataPayload.append("categoryId", categoryId);
  formDataPayload.append("thumbnail", files[0]);
  formDataPayload.append("brand", brand);
  try {
    const url = `${base_url}/trainingVideos/upload`;
    const { data } = await axiosAuthInstanceForFormData.post(url, formDataPayload);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const editTrainingVideoDetails = async (id, name, description, link, categoryId, files, selectedBrand) => {
  const formDataPayload = new FormData();
  formDataPayload.append("id", id);
  formDataPayload.append("name", name);
  formDataPayload.append("description", description);
  formDataPayload.append("link", link);
  formDataPayload.append("categoryId", categoryId);
  formDataPayload.append("brand", selectedBrand);
  if (files != undefined || files != null) {
    formDataPayload.append("thumbnail", files[0]);
  }
  try {
    const url = `${base_url}/trainingVideos/update`;
    const { data } = await axiosAuthInstanceForFormData.put(url, formDataPayload);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const deleteVideoDetails = async (id) => {
  try {
    const url = `${base_url}/trainingVideos/remove/${id}`;
    const { data } = await axiosAuthInstance.delete(url);
    // console.log("listAppUsers", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getVideoDetails = async (id) => {
  try {
    const url = `${base_url}/trainingVideos/videos/details/${id}`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const unsubscribeFromClevertape = async (msisdn) => {
  try {
    const url = `${base_url}/app/messaging/unsubscribe`;
    const { data } = await axiosAuthInstance.post(url, { msisdns: msisdn });
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const listAllMsisdns = async (msisdn) => {
  try {
    const url = `${base_url}/app/messaging/msisdn/list`;
    const { data } = await axiosAuthInstance.post(url, { msisdn: msisdn });
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getMetabaseHQDashboardLink = async (role, i_customer) => {
  try {
    const url = `${base_url}/app/reports/get-dashboard-link`;
    const { data } = await axiosAuthInstance.post(url, { role, i_customer });
    return data;
  } catch (ex) {
    console.error(ex);
  }
};

export const addLeveledSubDistributor = async (data) => {
  try {
    // console.log("addLeveledSubDistributor", data);

    const url = `${base_url}/app/distributor/sub-distributors/add`;
    const { data: response } = await axiosAuthInstance.post(url, data);
    return response;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getReferrerDistributors = async (i_customer) => {
  try {
    const url = `${base_url}/app/referrer-distributors?i_customer=${i_customer}`;
    const { data } = await axiosAuthInstance.get(url);
    // console.log("getReferrerDistributors", data);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const sellProduct = async ({ distributor_i_customer, msisdn, product_id, reference }) => {
  try {
    // console.log("sellProduct: ", distributor_i_customer, msisdn, product_id, reference);
    const url = `${base_url}/app/sellproduct`;
    const { data } = await axiosAuthInstance.post(url, { distributor_i_customer, msisdn, product_id, reference });
    // console.log("sellProduct", { data });
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const sellAirtime = async ({ distributor_i_customer, msisdn, amount, reference }) => {
  try {
    // console.log("sellAirtime: ", { distributor_i_customer, msisdn, amount, reference });
    const url = `${base_url}/app/sellairtime`;
    const { data } = await axiosAuthInstance.post(url, { distributor_i_customer, msisdn, amount, reference });
    // console.log("sellAirtime", { data });
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const allocatePromoData = async ({ distributor_i_customer, msisdn, amount, reference }) => {
  try {
    // console.log("allocatePromoData: ", { distributor_i_customer, msisdn, amount, reference });
    const url = `${base_url}/app/distributor/promo/data`;
    const { data } = await axiosAuthInstance.post(url, { distributor_i_customer, msisdn, amount, reference });
    // console.log("allocatePromoData", { data });
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getMerchantAccounts = async (distributor_i_customer) => {
  try {
    const url = `${base_url}/app/distributor/merchant/accounts/?distributor_i_customer=${distributor_i_customer}`;
    const { data } = await axiosAuthInstance.get(url);
    // console.log('getMerchantAccounts', data)
    return data;
    // [
    //   { label: 'The Shawshank Redemption', year: 1994 },
    //   { label: 'The Godfather', year: 1972 },
    //   { label: 'The Godfather: Part II', year: 1974 },
    //   { label: 'The Dark Knight', year: 2008 },
    //   { label: '12 Angry Men', year: 1957 },
    //   { label: "Schindler's List", year: 1993 },
    //   { label: 'Pulp Fiction', year: 1994 }];
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getWalletBatchRequests = async (distributor_i_customer, limit, page, searchText) => {
  try {
    let url = `${base_url}/app/wallet/batchRequest?distributor_i_customer=${distributor_i_customer}`;
    if (limit != null && page != null) {
      url += `&limit=${limit}&page=${page}`;
    }
    if (searchText && searchText != "") {
      url += `&searchText=${searchText}`;
    }
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getWalletBatchDetails = async (batch_request_id, limit, page) => {
  try {
    let url = `${base_url}/app/wallet/batchRequest/${batch_request_id}`;
    if (limit != null && page != null) {
      url += `?limit=${limit}&page=${page}`;
    }
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const getWalletMerchantAccountDetailsWithBalance = async (distributor_i_customer, limit, page) => {
  try {
    let url = `${base_url}/app/distributor/merchant/accounts/balances?distributor_i_customer=${distributor_i_customer}`;
    if (limit != null && page != null) {
      url += `&limit=${limit}&page=${page}`;
    }
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const downloadBatchRequestDetailsList = async (batch_request_id, email_address) => {
  try {
    let url = `${base_url}/app/wallet/batchRequest/download/${batch_request_id}?email_address=${email_address}`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const distributorBalanceTopup = async (distributor_i_customer, email_address, amount) => {
  try {
    const url = `${base_url}/app/distributor/top_up`;
    const { data } = await axiosAuthInstance.post(url, { distributor_i_customer, email_address, amount });
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const fetchPromoProductsData = async () => {
  try {
    let url = `${base_url}/app/distributor/promo-data-product`;
    const { data } = await axiosAuthInstance.get(url);
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const promoDataTopup = async (distributor_i_customer, email_address, product_id) => {
  try {
    let url = `${base_url}/app/distributor/promo_data_invoice`;
    const { data } = await axiosAuthInstance.post(url, { distributor_i_customer, email_address, product_id });
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};

export const smsCreditsTopup = async (distributor_i_customer, email_address, credits, cost) => {
  try {
    let url = `${base_url}/app/distributor/invoice/wholesale_sms_credits`;
    const { data } = await axiosAuthInstance.post(url, { distributor_i_customer, email_address, credits, cost });
    return data;
  } catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
};
