import PropTypes from "prop-types";
import { useEffect, useState } from "react";
// @mui
import { Button, Link, MenuItem, Stack, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
// utils
import ReactTimeAgo from "react-time-ago";
// components
import { useQuery } from "@tanstack/react-query";
import { getDistributorDetails } from "src/api/distributor-api";
import ConfirmDialog from "../../../../components/confirm-dialog";
import Iconify from "../../../../components/iconify";
import MenuPopover from "../../../../components/menu-popover";
// import icons from material icons
import {
  ApiTwoTone,
  Check,
  Clear,
  ContactPhoneTwoTone,
  HighQualityTwoTone,
  LanguageTwoTone,
  MailTwoTone,
  ManageAccountsTwoTone,
  NetworkCheckTwoTone,
  NoAccountsTwoTone,
  PermIdentityTwoTone,
  PhoneEnabledTwoTone,
  SimCardTwoTone,
  TextsmsTwoTone,
  WalletTwoTone,
} from "@mui/icons-material";
import moment from "moment";
// ----------------------------------------------------------------------

RuleManagementTableRow.propTypes = {
  row: PropTypes.object,
  ruleType: PropTypes.string,
  handleViewRule: PropTypes.func,
};

export default function RuleManagementTableRow({ row, ruleType, handleViewRule }) {
  return (
    <>
      <Link
        component={TableRow}
        hover
        sx={{ textDecoration: "none", cursor: "pointer" }}
        underline="none"
        onClick={(e) => {
          handleViewRule(e, row?.id);
        }}
      >
        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {row?.title}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="subtitle2" noWrap sx={{ textTransform: 'capitalize' }}>
            {ruleType}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {row?.promoStartDate ? moment(row?.promoStartDate).format("DD-MM-YYYY") : "-"}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {row?.promoEndDate ? moment(row?.promoEndDate).format("DD-MM-YYYY") : "-"}
          </Typography>
        </TableCell>
      </Link>
    </>
  );
}
