import * as Sentry from "@sentry/react";
import { axiosSparInstance } from "./axios";


const base_url = process.env.REACT_APP_SPAR_ENDPOINT



export const createPromoRule = async (promoData) => {
    try {
      const url = `/promo-rule`;
      const { data } = await axiosSparInstance.post(url, promoData);
      return data;
    } catch (ex) {
      if(ex.status == 409){
        return ex.response.data
      }
      console.error(ex);
      Sentry.captureException(ex);
    }
};

export const getPromoRuleList = async (ruleType,ruleSearchText,page,rowsPerPage) =>{
  try {
    let url = `/promo-rule/list?ruleType=${ruleType}&limit=${rowsPerPage}&page=${page}`;
    if(ruleSearchText){
      url += `&searchText=${ruleSearchText}`
    }
    const { data } = await axiosSparInstance.get(url);
    return data;
  } catch (ex) {
    if(ex.status == 409){
      return ex.response.data
    }
    console.error(ex);
    Sentry.captureException(ex);
  }
}


export const getPromoRuleData = async(id) =>{
  try{
    let url = `/promo-rule/${id}`;
    const { data } = await axiosSparInstance.get(url);

    return data;
  }catch (ex) {
    console.error(ex);
    Sentry.captureException(ex);
  }
}