import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// @mui
import { Icon } from "@iconify/react";
import { Box, Button, Divider, Link, MenuItem, Stack, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";

// routes
import { PATH_AUTH, PATH_DASHBOARD } from "../../../routes/paths";
// auth
import { useAuthContext } from "../../../auth/useAuthContext";
import { setRole } from "../../../redux/authSlice";
// components
import { IconButtonAnimate } from "../../../components/animate";
import { CustomAvatar } from "../../../components/custom-avatar";
import MenuPopover from "../../../components/menu-popover";
import { useSnackbar } from "../../../components/snackbar";
import Label from "src/components/label";

export default function AccountPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, logout } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();

  const userRoles = useSelector((state) => state.auth.user.roles);
  // sort roles by i_customer
  const sortedUserRoles = [...userRoles].sort((a, b) => {
    if (a.role === "admin") {
      return -1; // a should come before b
    } else if (b.role === "admin") {
      return 1; // b should come before a
    } else {
      return a.customer_name.toLowerCase().localeCompare(b.customer_name.toLowerCase());
    }
  });

  const selectedRole = useSelector((state) => state.auth.selectedRole);

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      logout();
      navigate(PATH_AUTH.loginAdmin, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  const handleClickItem = (role) => {
    handleClosePopover();
    dispatch(setRole(role));
    navigate("/");
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar alt={user?.displayName || "Username"} name={user?.displayName}>
          <Icon icon="eva:person-fill" />
        </CustomAvatar>
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ minWidth: 250, p: 0, overflowY: "auto" }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Link component={RouterLink} to={PATH_DASHBOARD.user.account} underline="none" color="inherit">
            <Typography variant="subtitle2" noWrap>
              {selectedRole?.customer_name}
            </Typography>

            <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
              {user?.username}
            </Typography>
          </Link>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {sortedUserRoles.map((role, index) => (
            <React.Fragment key={index}>
              <MenuItem onClick={() => handleClickItem(role)} sx={{ justifyContent: "space-between", my: 0, py: 0 }}>
                {/* customer_name if there is one else username */}
                <Typography variant="body2" noWrap sx={{ fontWeight: 500 }}>
                  {role?.customer_name || user.username}
                </Typography>
                {role?.customer_name && (
                  <Label
                    variant="soft"
                    color={
                      (role?.role === "admin" && "primary") ||
                      (role?.role === "distributor" && "secondary") ||
                      (role?.role === "sub-distributor" && "success") ||
                      (role?.role === "support_agent" && "success") ||
                      (role?.role === "iot" && "info") ||
                      (role?.role === "agent" && "warning") ||
                      "info"
                    }
                    sx={{ ml: 1 }}
                  >
                    {role?.role === "support_agent" ? "Support Agent" : role?.role}
                  </Label>
                )}
              </MenuItem>
              <Divider sx={{ borderStyle: "dashed" }} />
            </React.Fragment>
          ))}
        </Stack>

        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mx: 1, mb: 1, flexWrap: "nowrap" }}>
          <MenuItem component={RouterLink} to={PATH_DASHBOARD.user.account} onClick={handleClosePopover}>
            Change Password
          </MenuItem>

          <Button variant="contained" color="error" size="small" onClick={handleLogout} sx={{ whiteSpace: "nowrap", mx: 1 }}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
