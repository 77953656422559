import { Card, Divider, Tab, Table, TableBody, TableContainer, Tabs } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDistributorList } from "src/api/distributor-api";
import { listAllEscalations } from "src/api/megs-api-api";
import Label from "src/components/label";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import Scrollbar from "src/components/scrollbar";
import { TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, emptyRows, useTable } from "src/components/table";
import { PATH_DASHBOARD } from "src/routes/paths";
import EscalationTableRow from "./EscalationTableRow";
import EscalationTableToolbar from "./EscalationTableToolbar";

export default function EscalationList() {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date();
  tomorrow.setHours(0, 0, 0, 0);
  const navigate = useNavigate();
  const selectedRole = useSelector((state) => state.auth.selectedRole);
  const { i_customer } = selectedRole;
  const [escalations, setEscalations] = useState([]);
  const [escalationsData, setEscalationsData] = useState([]);
  const [escalationsDataForTabs, setEscalationsDataForTabs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedType, setSelectedType] = useState({ id: "All", name: "All" });
  const [search, setSearch] = useState("");
  const [tabStatus, setTabStatus] = useState("open");
  const [filterStartDate, setFilterStartDate] = useState(new Date(today.setDate(today.getDate() - 90)));
  const [filterEndDate, setFilterEndDate] = useState(new Date(tomorrow.setDate(tomorrow.getDate() + 1)));

  const [filterStatus, setFilterStatus] = useState("all");

  const [filterService, setFilterService] = useState(i_customer === 0 ? "all" : i_customer);

  const fetchDistributorList = async () => {
    try {
      const { data } = await getDistributorList();
      return data;
    } catch (error) {
      console.error("Error fetching distributor list:", error);
      return [];
    }
  };

  const { data: distributorList } = useQuery({
    queryKey: ["distributorList"],
    queryFn: fetchDistributorList,
    staleTime: 5 * 60000,
    keepPreviousData: true,
  });

  const Distributors = [{ i_customer: "all", company_name: "All" }, ...(distributorList || [])];

  const getLengthByStatus = (status) => escalationsDataForTabs.filter((item) => item.status === status).length;

  const TABLE_HEAD = [
    { id: "title", label: "Title", align: "left" },
    // { id: "role", label: "Role", align: "left" },
    { id: "app_user", label: "App User", align: "left" },
    { id: "description", label: "Description", align: "left" },
    { id: "priority", label: "Priority", align: "left" },
    { id: "status", label: "Status", align: "left" },
  ];

  const TABS = [
    {
      value: "open",
      label: "Open",
      color: "success",
      count: getLengthByStatus("open"),
    },
    {
      value: "close",
      label: "Closed",
      color: "primary",
      count: getLengthByStatus("close"),
    },
  ];

  const {
    dense,
    page,
    setPage,
    order,
    orderBy,
    rowsPerPage,
    // setRowsPerPage,
    //
    selected,
    // setSelected,
    // onSelectRow,
    // onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const denseHeight = dense ? 56 : 76;

  const isFiltered = filterStatus !== "all" || search !== "" || filterService !== "all" || (!!filterStartDate && !!filterEndDate);

  const handleSearch = useCallback((event) => {
    setPage(0);
    setSearch(event.target.value);
  }, []);

  const handleFilterService = useCallback((event) => {
    setPage(0);
    setFilterService(event.target.value);
  }, []);

  const handleResetFilter = useCallback(() => {
    setSearch("");
    setFilterStatus("all");
    setFilterService(i_customer === 0 ? "all" : i_customer);
    // set date to 90 days ago
    setFilterStartDate(new Date(today.setDate(today.getDate() - 90)));
    setFilterEndDate(new Date(tomorrow.setDate(tomorrow.getDate() + 1)));
    sessionStorage.removeItem("search");
    sessionStorage.removeItem("filterStatus");
    sessionStorage.removeItem("filterService");
    sessionStorage.removeItem("filterEndDate");
    sessionStorage.removeItem("filterStartDate");
  }, [i_customer]);

  useEffect(() => {
    async function fetchEscalations() {
      setIsLoading(true);
      setError(null);
      try {
        const response = await listAllEscalations(i_customer, tabStatus, filterStartDate, filterEndDate);
        console.log("response", response);
        if (response.status_code !== 1001) {
          throw new Error("Failed to fetch escalations");
        }
        const data = await response.data;
        setEscalations(data);
        setEscalationsData(data);
        setEscalationsDataForTabs(data);
      } catch (error) {
        console.log("error", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    }
    if (tabStatus === "close" && filterStartDate && filterEndDate) {
      fetchEscalations();
    }
    if (tabStatus === "open") {
      fetchEscalations();
    }
  }, [tabStatus, filterStartDate, filterEndDate, i_customer]);

  const onSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleTypeChange = (event, newValue) => {
    setSelectedType(newValue);
  };

  const handleViewRow = (id) => {
    navigate(PATH_DASHBOARD.customerCare.viewEscalation(id));
  };

  const handleTabStatus = (event, newValue) => {
    setTabStatus(newValue);
  };

  const handleFilterStartDate = useCallback((newValue) => {
    setFilterStartDate(newValue);
  }, []);

  const handleFilterEndDate = useCallback((newValue) => {
    setFilterEndDate(newValue);
  }, []);

  useEffect(() => {
    const filteredEscalationsData = escalationsData?.filter((escalation) => {
      const isStatusMatch = (tabStatus === "open" && escalation?.status === "open") || (tabStatus === "close" && escalation?.status === "close");

      // const isTypeMatch = selectedType?.id === "All" || product?.product_type_id === selectedType?.id;

      const isSearchMatch =
        escalation?.app_user?.toLowerCase().includes(search.toLowerCase()) ||
        escalation?.description?.toLowerCase().includes(search.toLowerCase()) ||
        escalation?.title?.toLowerCase().includes(search.toLowerCase()) ||
        escalation?.msisdn?.toString().includes(search.toString());

      const isDateMatch = new Date(escalation?.created_at) >= filterStartDate && new Date(escalation?.created_at) <= filterEndDate;

      const isServiceMatch = filterService === "all" || escalation?.distributor_i_customer === filterService;

      return isStatusMatch && isSearchMatch && isDateMatch && isServiceMatch;
    });

    setEscalations([...filteredEscalationsData]);
  }, [search, tabStatus, filterStartDate, filterEndDate, escalationsData, filterService]);

  if (isLoading) return <LoadingScreen />;

  if (error) return <Card sx={{ p: 3 }}>Error: {error}</Card>;

  return (
    <>
      <Card>
        <Tabs
          value={tabStatus}
          onChange={handleTabStatus}
          sx={{
            px: 2,
            bgcolor: "background.neutral",
          }}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              icon={
                <Label color={tab.color} sx={{ mr: 1 }}>
                  {tab.count}
                </Label>
              }
            />
          ))}
        </Tabs>
        <Divider />
        <EscalationTableToolbar
          filterName={search}
          isFiltered={isFiltered}
          filterService={filterService}
          filterEndDate={filterEndDate}
          onFilterName={handleSearch}
          optionsService={
            selectedRole.role === "admin"
              ? Distributors
              : [
                  {
                    i_customer: i_customer,
                    // get company name from Distributors
                    company_name: Distributors.find((distributor) => distributor.i_customer === i_customer)?.company_name,
                  },
                ]
          }
          filterStartDate={filterStartDate}
          onResetFilter={handleResetFilter}
          onFilterService={handleFilterService}
          onFilterStartDate={handleFilterStartDate}
          onFilterEndDate={handleFilterEndDate}
          selectedRole={selectedRole?.role}
        />
        <Divider />
        <TableContainer sx={{ position: "relative", overflow: "unset" }}>
          <Scrollbar>
            <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={escalations.length}
                numSelected={selected.length}
                onRequestSort={onSort}
              />
              <TableBody>
                {escalations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((escalation, index) => (
                  <EscalationTableRow key={index} row={escalation} onViewRow={() => handleViewRow(escalation.id)} />
                ))}
                <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, escalations.length)} />
                <TableNoData isNotFound={escalations.length === 0} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
          count={escalations.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onChangeRowsPerPage}
          onPageChange={onChangePage}
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Card>
    </>
  );
}
